<template>
  <div>
    <!-- 進階搜尋時間區間 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">進階搜尋時間區間</p>
      <div class="form-floating mb-3">
        <input
          onwheel="this.blur()"
          type="number"
          class="form-control"
          id="SearchTimeRange"
          placeholder="時間區間(最多 90 天)"
          max="90"
          v-model="p_purchaseOrder.searchTimeRange[0].value"
          pattern="[0-9]*"
          oninput="this.value=this.value.replace(/\D/g,'')"
        />
        <label for="SearchTimeRange">時間區間(最多 90 天)</label>
      </div>
      <div class="text-end">
        <button
          class="tw-btn tw-btn-success"
          @click="
            updateConfiguration(
              'timeRange',
              p_purchaseOrder.searchTimeRange[0].value
            )
          "
        >
          儲存
        </button>
      </div>
    </div>
    <!-- 表格呈現模式 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">表格呈現模式</p>
      <div class="select-radio">
        <label v-for="data in p_purchaseOrder.d4DataTable" :key="data.id">
          <input
            type="radio"
            name="d4DataTable"
            :checked="data.selected"
            @click="updateConfiguration('d4DataTable', data)"
          />
          <span class="radio-style">
            <span v-if="data.value === 'header'">叫貨單模式</span>
            <span v-else>明細模式</span>
          </span>
        </label>
      </div>
    </div>
    <!-- 顯示行數 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">顯示行數</p>
      <div class="select-radio">
        <label v-for="data in p_purchaseOrder.rowCount" :key="data.id">
          <input
            type="radio"
            name="rows"
            :checked="data.selected"
            @click="updateConfiguration('row', data)"
          />
          <span class="radio-style">{{ data.value }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      storeId: 0,
      serverToken: '',
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
  },
  computed: {
    ...mapState('Prefer', {
      p_loading: state => state.p_loading,
      p_purchaseOrder: state => state.p_purchaseOrder,
    })
  },
  methods: {
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 更新偏好
    updateConfiguration(item, data) {
      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: 'p_purchaseOrder',
        item: item,
        data: data
      })
    },
  },
}
</script>